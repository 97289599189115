import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as BtnSend } from "./svg/send.svg";
import { mapClient } from "../../../api/auth/AxiosInstanse";
import {
  NotificationState,
  NotificationType,
} from "../../Notification/notificationTypes";
import { useState, useEffect } from "react";

interface ButtonSendSmsProps {
  uvi: string | undefined;
  numberPhone: boolean;
  showNotification: NotificationState;
  onChangeNotificationState: (state: NotificationState) => void;
}

function ButtonSendSms({
  uvi,
  numberPhone,
  showNotification,
  onChangeNotificationState,
}: ButtonSendSmsProps) {
  const intl = useIntl();
  const [lastSentTime, setLastSentTime] = useState<number | null>(null);
  const [timer, setTimer] = useState<number>(0);

  useEffect(() => {
    const savedLastSentTime = localStorage.getItem("lastSentTime");
    if (savedLastSentTime) {
      setLastSentTime(Number(savedLastSentTime));
    }
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (lastSentTime) {
      const currentTime = Date.now();
      const diff = 60000 - (currentTime - lastSentTime);

      if (diff > 0) {
        setTimer(Math.ceil(diff / 1000));
        interval = setInterval(() => {
          setTimer((prev) => {
            if (prev <= 1) {
              clearInterval(interval!);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      }
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [lastSentTime]);

  const handleSendSms = async () => {
    const currentTime = Date.now();

    if (lastSentTime && currentTime - lastSentTime < 1000) {
      console.warn("Сообщение отправлено слишком рано. Подождите секунду.");
      return;
    }

    if (!uvi) {
      onChangeNotificationState({
        type: NotificationType.error,
        message: intl.formatMessage({ id: "send_sms_error_no_uvi" }),
        isShow: true,
      });
      console.error("UVI отсутствует. СМС не отправлено.");
      return;
    }

    try {
      const response = await mapClient.sendModuleSettingsViaSms(uvi);
      setLastSentTime(currentTime);
      localStorage.setItem("lastSentTime", String(currentTime));
      setTimer(60);
      onChangeNotificationState({
        type: NotificationType.info,
        message: intl.formatMessage({ id: "send_sms_success" }),
        isShow: true,
      });
      console.log("СМС успешно отправлено: ", response);
    } catch (error) {
      onChangeNotificationState({
        type: NotificationType.error,
        message: intl.formatMessage({ id: "send_sms_error" }),
        isShow: true,
      });
      console.error("Ошибка при отправке СМС:", error);
    }
  };

  return (
    <button
      type="button"
      className={`btn ml-5 !bg-color14 !w-auto !px-8 ${timer > 0 || !numberPhone ? "disabled" : ""}`}
      onClick={handleSendSms}
      disabled={timer > 0 || !numberPhone}
    >
      <BtnSend />
      <span className="ml-2.5">
        <FormattedMessage id="send_sms_button" />
      </span>
      {timer > 0 && (
        <span className="ml-2.5 text-sm">
          {intl.formatMessage({ id: "send_sms_wait" }, { time: timer })}
        </span>
      )}
    </button>
  );
}

export default ButtonSendSms;

import { LOCALES } from "./locales";
export const messages = {
  [LOCALES.ENGLISH]: {
    signUp: "Sign Up",
    signUp_create: "Create Account",
    signUp_companyToken: "Company registration number",
    signUp_email: "Email",
    signUp_password: "Password",
    singUp_password_repeat: "Repeat password",
    signUp_submit: "Submit",
    signUp_logIN: "Log In",
    signUp_question: "Already have an account?",
    signUp_success:
      "Successfully! Confirm registration by clicking on the link sent to your email",
    signUp_errors: "The user with the specified login already exists",
    accept: "I agree to the processing of my personal data",

    login_title: "Sign In!",
    login_subtitle: "Enter your username and password to authenticate",
    login: "Email",
    password: "Password",
    logIn_forgot: "Forgot Password?",
    logIn_remember: "Remember me",
    entry: "Login",
    logIn_create_account: "Create an Account",
    login_question: "Do you have an account?",
    logIn_message_success: "Successful authorization",
    login_message_errors: "Invalid username and/or password",
    login_message_warning: "",
    login_email_notConfirm: "Email not verified. Confirmation email resent",
    login_in_demo: "Login in demo",

    token_updated_message: "Session successfully extended",
    token_message_expiration: "Your session has expired, please try again",
    reset_password: "Password updated successfully",
    reset_password_error:
      "Failed to reset your password, try again or request the email again",

    PWReset: "Reset Password",
    PWReset_password_reset: "Password Reset",
    PWReset_reset_link: "A reset link will be sent to your inbox",
    PWReset_email: "Email",
    PWReset_reset_password: "Send Reset Link",
    PWReset_go_back: "Go Back",
    send_letter: "An email to reset your password has been sent to you",
    error_send_letter:
      "There was an error sending the email! Request email again",

    data_receiving_error: "Data receiving error",
    data_functions_error: "Feature list not received",

    users: "Users",
    user_name: "Name",
    user_surname: "Surname",
    user_role: "Admin",

    administration: "Software Update",
    vehicles_list: "Transport list",
    system_list: "System log",
    change_password_menu: "Change password",
    log_out: "Log Out",

    change_password_title: "Change password",
    current_password_title: "Current Password",
    change_current_password: "Password changed successfully",
    error_current_password:
      "An error occurred while changing the password. try again",
    change_password_question: "Do you want to cancel the password change?",
    placeholder_current_password: "Enter password",
    new_password: "New password",
    placeholder_new_password: "Enter a new password",
    confirm_password_title: "Repeat password",
    create_new_password_title: "Creating a new password",

    transportSearch: "Transport search",
    tableSearch: "Search",

    verification_title: "Your email has been confirmed!",
    verification_subtitle:
      "To get started, you need to log in, click on button below",
    verification: "Login",

    save: "Save",
    edit: "Edit",
    cancel: "Cancel",
    leave_page: "Go out",
    stay_page: "Stay",
    restore: "Restore",
    overwrite: "Overwrite",
    confirm: "Confirm",
    delete: "Delete",
    add_criteria: "Add criteria",
    button_back: "Back",
    rename: "Rename",
    add_can: "Add new CAN ID",
    revoke: "Revoke tokens",
    create: "Create",
    close: "Close",
    update: "Update",
    settings_module_button: "Module settings",
    restart_button: "Restart",
    send_sms_button: "Send SMS with settings",
    send_sms_success: "Message successfully sent",
    send_sms_error: "Error while sending SMS. Try again",
    send_sms_warning_wait: "Send SMS again after 60 seconds!",
    send_sms_error_no_uvi: "UVI is missing. No SMS has been sent",
    send_sms_wait: "in {time}s",
    button_upload: "Upload",
    button_clear: "Clear",
    search: "Search",

    input_required: "Required field",
    input_maxLength: "Maximum 38 characters for field",
    validate_password:
      "Password at least 6 characters,must contain lowercase/uppercase letters, numbers, at least one special character #?!@$%^&*-+",
    validate_email: "Invalid email address format",
    match_password: "Passwords must match",
    validate_format_Guid: "Invalid GUID format",
    validate_CanID: "Can ID cannot contain Cyrillic or special characters",
    validate_value: "Valid values 0 or 1",
    validate_parameterId: "Valid values (=) or (≠)",
    canId_validation: "Only HEX is allowed (without 0x)",
    canId_validation_extended_true: "Can ID length must be 8 characters",
    canId_validation_extended_false: "Can ID length must be 3 characters",
    canId_validation_extended_true_maxValue: "Maximum value 1FFFFFFF",
    canId_validation_extended_false_maxValue: "Maximum value 7FF",
    max_length_error: "The maximum length is 45",
    pgn_validation: "Only 5 digits in PGN",

    transportTitle: "Creating a new vehicle",
    nameTransport: "Transport name",
    number: "Transport number",
    vin: "Vin number",
    notes: "Notes",
    transport_coordinates: "Transport coordinates",
    coordinate_collection_period: "Coordinate collection period",
    transportImei: "Imei transport",
    transport_UVI: "UVI",
    settings_module: "Module settings",
    simNumber: "Sim-number",
    simPin: "Sim-pin",
    canInterfaceSpeed: "Can interface speed",
    versionFirmware: "Firmware version",
    logSize: "Log size",
    validate_number: "Only positive numbers are allowed",
    type_transport: "Type of transport",
    placeholder_name_transport: "Enter the name of the transport",
    placeholder_imei_transport: "Enter Imei",
    placeholder_logSize: "Enter log size",
    placeholder_phone_number: "Start typing your phone number...",
    placeholder_simPin: "Enter Sim-pin",
    placeholder_Vin: "Enter VIN number",
    placeholder_licensyPlate: "Enter transport number",
    placeholder_type_transport: "Select type of transport",
    placeholder_coordinates: "X1 Y1",
    coordinates_text: "Coordinates not specified",
    placeholder_notes: "Text description...",
    placeholder_collection_period: "Select period",
    createTransportQuestion:
      "Do you want to cancel the creation of the transport?",
    archived_transport: "The car indicated by Imei was found in the archive",
    archived_transport_title:
      "A vehicle with the specified Imei was found in the archive",
    archivedTransportQuestion:
      "Should I restore the vehicle from the archive or overwrite it with new data?",
    duplicate_vehicle: "A machine with the specified Imei already exists",
    duplicate_imei_vehicle: "Failed to save changes",
    deleteTransportQuestion: "Are you sure you want to delete this transport?",
    deleteTransportSuccess: "Transport successfully deleted",
    deleteTransportError: "Error deleting transport",
    buttonYes: "Yes",
    buttonNo: "No",
    select_format: "What protocol are you going to use?",
    transport_info: "There are unsaved changes",
    transport_question: "Are you sure you want to leave the page?",
    transport_warning: "Unsaved changes will be lost.",
    transport_error_byId: "Failed to get vehicle data",
    FunctionsAndTransportUpdateSuccess: "Data updated successfully",
    FunctionsAndTransportUpdateFail: "Error while updating data",
    error_clickTransport: "Vehicle selection is not available in editing mode",
    function_delete_success: "Feature removed",
    function_delete_error: "An error occurred during deletion",
    vehicle_change_success: "Data updated successfully",
    createTransportSuccess: "Transport created successfully",
    createTransportError: "Failed to create transport",
    getModuleError: "Failed to get module",
    getUpdateError: "Failed to update data",
    resetModuleSuccess: "Command to reset successfully sent to module",
    resetModuleError: "Error restarting module",
    resetModuleQuestion: "Are you sure you want to restart the module?",
    connection_settings_confirmed: "Configuration confirmed",
    connection_settings_notConfirmed: "Configuration not confirmed",

    table_page_text: "Show on page",
    table_select_allPages: "All",
    table_empty: "No data to display",

    add_function: "Add feature",
    update_function: "Refresh",
    transport_list: "List of transport",
    add_new: "Add new",
    create_function_success: "Function has been created successfully",
    create_function_error: "Failed to create function",
    update_function_success: "Function changed successfully",
    update_function_error: "Failed to update function",
    add_function_error: "Failed to add function",
    deleteFunctionQuestion: "Are you sure you want to remove this feature?",
    create_function_title: "Creating a Function",
    edit_function_title: "Editing a function",
    add_new_groups_success: "The group was added successfully",
    add_new_groups_error: "Failed to create group",
    reName_groups_success: "Data changed successfully",
    reName_groups_errors: "Failed to rename group",
    delete_groups_success: "Group deleted",
    delete_groups_error: "Failed to delete group",
    deleteGroupsQuestion: "Are you sure you want to delete this group?",
    format_not_selected: "Format not defined",

    group: "Group",
    changes_group: "Editing groups",
    add_new_group: "A new group",
    group_title: "Function groups",
    placeholder_choose_group: "Select group",
    placeholder_search_group: "Group search",
    data_collection_period: "Module data collection period",
    add_group: "Add group:",
    name: "Title",
    placeholder_name: "Enter the title",
    can_id: "CAN ID",
    placeholder_canId: "Select CAN ID",
    placeholder_pgn: "Select PGN",
    can_type: "CAN Type",
    placeholder_canType: "Enter CAN Type",
    date_time: "Date / time",
    status: "Status",
    result: "Result",
    date: "Date",
    action: "Action",
    check_uvi: "To create a function, you must fill UVI",
    empty_table: "No data to display",
    toggle_text: "Result Format: Dec/Hex",
    choice_period_Title: "Select period",

    changes_CanId: "Editing",
    data_can_error: "Data receiving error",
    search_CanId: "Search",
    can_name: "Title",
    can_name_placeholder: "Enter a name",
    can_mask: "Mask",
    canId: "ID",
    protocol: "Protocol",
    can_period: "Period",
    can_type_extended: "CAN Type",
    can_action: "Actions",
    placeholder_choose_can_protocol: "Select protocol",
    placeholder_choose_period: "Enter the period",
    changes_can_success: "Changes saved",
    changes_can_error: "Failed to save changes",
    update_can_identifier_success: "Identifier changed successfully",
    update_can_identifier_error: "Failed to change identifier",
    delete_can_identifier_question:
      "Are you sure you want to delete this identifier?",
    cancel_action_question: "Are you sure you want to cancel your changes?",
    can_input_required: "Required field",
    can_configurationConfirmed_success: "Configuration confirmed",
    can_configurationConfirmed_error: "Configuration not confirmed",
    can_update_success: "Data updated successfully",
    can_update_error: "Failed to update data",
    can_period_validation: "Decimals only",
    can_period_min_error: "The minimum value is 0.001",
    can_downloaded: "Downloaded",
    can_from: "from",
    can_unconfirmed: "/ Unconfirmed",
    can_read_more: "Read More",
    can_attention: "Attention!",
    can_attention_extends:
      "not confirmed. Resend the data or change the CAN ID parameters.",
    can_format_validate_standard:
      "Maximum number of identifiers with standard format (11 bit) - 2",
    can_format_validate_extended:
      "Maximum number of IDs with extended format (29 bit) - 13",

    data_type: "Data type",
    data_type_label: "Number of bytes",
    placeholder_choose_data_type: "Select data type",
    data_byte: "Byte number",
    placeholder_choose_data_byte: "Select byte number",
    data_bit: "Bit numbers",
    placeholder_choose_data_bit: "Bit number",
    signed_unsigned: "Data format",
    placeholder_signed_unsigned: "Data format",
    order: "Byte order",
    placeholder_order: "Byte order",
    status_settings_title: "Status settings",
    status_settings_choice: "Select status",
    status_error_table: "Error",
    status_success_table: "Success",
    status_undefined_table: "Undefined",
    criteria_params_title: "Options",
    placeholder_criteria_params: "Select an option",
    value_title: "Value",
    placeholder_value: "Select value",
    placeholder_value_input: "Enter value",
    module_roaming: "Roaming",
    transportAPN: "APN(Sim)",
    placeholder_APN_transport: "Enter APN",
    nameAPN: "Name APN",
    placeholder_userName_transport: "Enter user name",
    PasswordAPN: "Password APN",
    placeholder_password_transport: "Enter sim-password",
    numberPhoneModule: "Phone number",
    pinSimCard: "Pin Sim-card",
    settings_title_module_block: "Module settings",
    placeholder_pin_transport: "Enter sim-pin",
    placeholder_phoneNumber_transport: "Enter phone number",
    placeholder_spn: "Select SPN",

    error_page_title: "Page not found",

    user_list_title: "User list",
    user_email_confirmed: "Confirmed",
    user_email_notConfirmed: "Not confirmed",
    user_id: "User ID",
    user_email: "User Email",
    user_email_status: "Email Status",
    user_email_confirm: "Confirmed",
    user_email_not_confirm: "Not confirmed",
    user_creation_date: "User creation date",
    user_roles: "Roles",
    user_refresh_token_expiry_time: "Refresh token expiration date",
    user_revoke_token_question:
      "Are you sure you want to revoke the user's token?",
    user_revoke_token_success: "User token successfully revoked",
    user_revoke_token_error: "Failed to revoke user token",
    user_revoke_all_token_question: "Do you want to revoke all user tokens?",
    user_revoke_all_token_success: "All user tokens successfully revoked",
    user_revoke_all_token_error: "Failed to revoke user tokens",
    user_revoke_token: "Revoke user token",
    revoke_company_token_success: "Company token successfully revoked",
    revoke_company_token_error: "Failed to revoke company token",
    revoke_token_question: "Are you sure you want to revoke the token?",
    update_company_token: "Token update",
    update_company_token_success: "Company token successfully updated",
    update_company_token_error: "Failed to update company token",
    update_company_token_question: "Do you want to cancel the token update?",

    roles_title: "Roles",
    current_role: "Current role",
    all_roles: "All roles",
    role_assigned_success: "Role assigned successfully",
    role_assigned_error: "Failed to assign role",
    change_role: "Change role",

    chosen: "Chosen",

    create_new_user: "Create user",
    userName: "User nickname",
    placeholder_userName: "Enter user nickname",
    email: "User email",
    placeholder_email: "Enter user email",
    login_user: "User login",
    placeholder_login_user: "Enter user login",
    password_user: "User password",
    placeholder_password_user: "Enter user password",
    firstName: "Username",
    placeholder_firstName: "Enter your username",
    secondName: "User's last name",
    placeholder_secondName: "Enter user's last name",
    dateBirth: "Date of birth",
    placeholder_dateBirth: "Select date of birth",
    user_gender: "Gender",
    user_man: "Male",
    user_female: "Female",
    createUser_success: "User created successfully",
    createUser_error: "Failed to create user",
    placeholder_user_gender: "Select gender",
    users_roles: "Role",
    placeholder_users_roles: "Select a role",
    user_create_question: "Do you want to cancel user creation?",
    user_update_question: "Do you want to cancel user editing?",
    updateUser_success: "User updated successfully",
    updateUser_error: "Failed to update user",
    update_user_title: "Editing a user",
    user_delete_user_question: "Are you sure you want to delete this user?",
    user_delete_user_success: "User deleted successfully",
    user_delete_user_error: "Failed to delete user",

    softwareVersion: "Software version",
    status_error_update: "Error",
    status_success_update: "Success",
    status_loading_update: "Uploading",
    transport_name_title: "Transport",
    transport_uvi_title: "UVI",
    transport_software_version_title: "Software version",
    transport_status_title: "Status",
    transport_date_time_title: "Date",
    update_success: "software update has started",
    show_only_title: "Show only",
    search_by_date: "Search by date",
    clear_choice: "Clear choice",

    connection_history: "Connection history",
    log_table_status: "Status",
    log_table_date: "Date",
    getLogModuleError: "Failed to get module log",
    updateLogModuleSuccess: "Log updated successfully",
    updateLogModuleError: "Failed to update log",
    clearLogModuleSuccess: "Log cleared successfully",
    clearLogModuleError: "Failed to clear log",
    deleteLogQuestion: "Are you sure you want to delete this log?",
    chronology: "Chronology",
    accuracyGps: "GPS accuracy",
    apply: "Apply",
    locationOfTransportation: "Location of Transportation",
    send: "Send",
    missing_uvi_error_message: "UVI value could not be found",
    error_message_protocol:
      "Maximum number of identifiers with standard format - 2",
    error_message_id: "There's already an ID like that",
    pgn_duplicate_error: "There's already a PGN like that",
  },
  [LOCALES.RUSSIAN]: {
    signUp: "Регистрация",
    signUp_create: "Создание аккаунта",
    signUp_companyToken: "Регистрационный номер компании",
    signUp_email: "Email",
    signUp_password: "Пароль",
    singUp_password_repeat: "Повторите пароль",
    signUp_submit: "Подтвердить",
    signUp_logIN: "Войдите",
    signUp_question: "Уже есть аккаунт?",
    signUp_success:
      "Успешно! Подвердите регистрацию, перейдя по ссылке, отправленной на ваш email",
    signUp_errors: "Пользователь с указанным логином уже существует",
    accept: "Даю согласие на обработку своих персональных данных",

    login_title: "Вход в аккаунт",
    login_subtitle: "Для аутентификации введите ваш логин и пароль",
    login: "Электронная почта",
    password: "Пароль",
    logIn_forgot: "Восстановление пароля",
    logIn_remember: "Запомнить меня",
    entry: "Вход",
    logIn_create_account: "Зарегистироваться",
    login_question: "У вас нет аккаунта?",
    logIn_message_success: "Авторизация успешна",
    login_message_errors: "Неверный логин и(или) пароль",
    login_message_warning: "",
    login_email_notConfirm:
      "Email не подтвержден. Письмо для подтверждения отправлено повторно",
    login_in_demo: "Вход в демо",

    token_updated_message: "Сессия успешна продлена",
    token_message_expiration:
      "Ваша сессия истекла, повторите попытку авторизации",
    reset_password: "Пароль успешно обновлен",
    reset_password_error:
      "Не удалось сбросить пароль, попробуйте еще раз или запросите письмо повторно",

    PWReset: "Сбросить пароль",
    PWReset_password_reset: "Сброс пароля",
    PWReset_reset_link: "Ссылка для сброса будет отправлена на ваш email",
    PWReset_email: "Email",
    PWReset_reset_password: "Сброс пароля",
    PWReset_go_back: "Назад",
    send_letter: "Письмо для сброса пароля отправлено вам на email",
    error_send_letter:
      "Произошла ошибка при отправке письма! Запросите письмо повторно",

    data_receiving_error: "Ошибка получения данных",
    data_functions_error: "Список функций не получен",

    users: "Пользователи",
    user_name: "Имя",
    user_surname: "Фамилия",
    user_role: "Админ",

    administration: "Обновление ПО",
    vehicles_list: "Список транспорта",
    system_list: "Системный журнал",
    change_password_menu: "Сменить пароль",
    log_out: "Выйти",

    change_password_title: "Сменить пароль",
    current_password_title: "Текущий пароль",
    change_current_password: "Пароль успешно изменен",
    error_current_password:
      "Произошла ошибка при изменении пароля. Попробуйте еще раз",
    change_password_question: "Вы хотите отменить смену пароля?",
    placeholder_current_password: "Введите пароль",
    new_password: "Новый пароль",
    placeholder_new_password: "Введите новый пароль",
    confirm_password_title: "Повторите пароль",
    create_new_password_title: "Создание нового пароля",

    transportSearch: "Поиск транспорта",
    tableSearch: "Поиск",

    verification_title: "Ваш email подтвержден!",
    verification_subtitle:
      "Чтобы приступить к работе необходимо авторизоваться, нажмите на кнопку ниже",
    verification: "Авторизоваться",

    save: "Сохранить",
    edit: "Редактировать",
    cancel: "Отмена",
    leave_page: "Покинуть",
    stay_page: "Остаться",
    restore: "Восстановить",
    overwrite: "Перезаписать",
    confirm: "Подтвердить",
    delete: "Удалить",
    add_criteria: "Добавить критерии",
    button_back: "Назад",
    rename: "Переименовать",
    add_can: "Добавить новый CAN ID",
    revoke: "Отозвать токены",
    create: "Создать",
    close: "Закрыть",
    update: "Обновить",
    settings_module_button: "Настройки модуля",
    restart_button: "Перезагрузить",
    send_sms_button: "Отправить СМС с настройками",
    send_sms_success: "Сообщение успешно отправлено",
    send_sms_error: "Ошибка при отправке СМС. Попробуйте снова",
    send_sms_warning_wait: "Повторная отправка смс через 60 секунд!",
    send_sms_error_no_uvi: "UVI отсутствует. СМС не отправлено",
    send_sms_wait: "через {time}с",
    button_upload: "Загрузить",
    button_clear: "Очистить",
    search: "Найти",

    input_required: "Обязательное поле",
    input_maxLength: "Для поля максимум 38 знаков",
    validate_password:
      "Пароль минимум 6 символов, строчные/заглавные буквы, цифры, как минимум один символ #?!@$%^&*-+",
    validate_email: "Неверный формат адреса электронной почты",
    match_password: "Пароли должны совпадать",
    validate_format_Guid: "Неверный формат GUID",
    validate_CanID:
      "Can ID не может содержать кириллицу или специальные символы",
    validate_value: "Допустимые значения 0 или 1",
    validate_parameterId: "Допустимые значения (=) или (≠)",
    canId_validation: "Допустим только HEX",
    canId_validation_extended_true: "Длина Can ID должна быть 8 символов",
    canId_validation_extended_false: "Длина Can ID должна быть 3 символа",
    canId_validation_extended_true_maxValue: "Максимальное значение 1FFFFFFF",
    canId_validation_extended_false_maxValue: "Максимальное значение 7FF",
    max_length_error: "Максимальная длина - 45",
    pgn_validation: "Только 5 цифр в PGN",

    transportTitle: "Создание нового транспорта",
    nameTransport: "Название транспорта",
    number: "Номер транспорта",
    vin: "Vin номер",
    notes: "Заметки",
    transport_coordinates: "Координаты транспорта",
    coordinate_collection_period: "Период сбора координат",
    transportImei: "Imei транспорта",
    transport_UVI: "УИТ",
    settings_module: "Настройки модуля",
    simNumber: "Sim-номер",
    simPin: "Sim-pin",
    canInterfaceSpeed: "Скорость Can интерфейса",
    versionFirmware: "Версия ПО",
    logSize: "Размер Лога",
    validate_number: "Допустимы только положительные числа",
    type_transport: "Тип транспорта",
    placeholder_name_transport: "Введите название транспорта",
    placeholder_imei_transport: "Введите Imei",
    placeholder_logSize: "Введите размер лога",
    placeholder_phone_number: "Начините вводить номер телефона...",
    placeholder_simPin: "Введите Sim Pin",
    placeholder_Vin: "Введите VIN номер",
    placeholder_licensyPlate: "Введите номер транспорта",
    placeholder_type_transport: "Выберите тип транспорта",
    placeholder_coordinates: "X1 Y1",
    coordinates_text: "Координаты не заданы",
    placeholder_notes: "Текстовое описание...",
    placeholder_collection_period: "Выберите период",
    createTransportQuestion: "Вы хотите отменить создание транспорта?",
    archived_transport: "Машина указанным Imei найдена в архиве",
    archived_transport_title:
      "Транспортное средство с указанным Imei найдено в архиве",
    archivedTransportQuestion:
      "Восстановить транспортное средство из архива или перезаписать новыми данными?",
    deleteTransportQuestion: "Вы уверены, что хотите удалить этот транспорт?",
    deleteTransportSuccess: "Транспорт успешно удален",
    deleteTransportError: "Ошибка при удалении транспорта",
    duplicate_vehicle: "Машина с указанным Imei уже существует",
    duplicate_imei_vehicle: "Не удалось сохранить изменения",
    buttonYes: "Да",
    buttonNo: "Нет",
    select_format: "Какой протокол вы собираетесь использовать?",
    transport_info: "Остались несохраненные изменения",
    transport_question: "Вы точно хотите покинуть страницу?",
    transport_warning: "Несохраненные изменения будут потеряны.",
    transport_error_byId: "Не удалось получить данные транспортного средства",
    FunctionsAndTransportUpdateSuccess: "Данные обновлены успешно",
    FunctionsAndTransportUpdateFail: "Ошибка при обновлении данных",
    function_delete_success: "Функция удалена",
    function_delete_error: "При удалении произошла ошибка",
    vehicle_change_success: "Данные успешно обновлены",
    createTransportSuccess: "Транспорт успешно создан",
    createTransportError: "Не удалось создать транспорт",
    getModuleError: "Не удалось получить модуль",
    getUpdateError: "Не удалось обновить данные",
    resetModuleSuccess: "Команда на сброс успешно отправлена в модуль",
    resetModuleError: "Ошибка при перезагрузке модуля",
    resetModuleQuestion: "Вы уверены, что хотите перезагрузить модуль?",
    connection_settings_confirmed: "Конфигурация подтверждена",
    connection_settings_notConfirmed: "Конфигурация не подтверждена",

    table_page_text: "Показывать по",
    table_select_allPages: "Все",
    table_empty: "Нет данных для отображения",

    add_function: "Добавить функцию",
    update_function: "Обновить",
    transport_list: "Список транспорта",
    add_new: "Добавить новый",
    create_function_success: "Функция была успешно создана",
    create_function_error: "Не удалось создать функцию",
    update_function_success: "Функциия успешно изменена",
    update_function_error: "Не удалось обновить функцию",
    add_function_error: "Не удалось добавить функцию",
    error_clickTransport: "Выбор машин недоступен в режиме редактирования",
    deleteFunctionQuestion: "Вы уверены, что хотите удалить эту функцию?",
    create_function_title: "Создание функции",
    edit_function_title: "Редактирование функции",
    add_new_groups_success: "Группа была успешно добавлена",
    add_new_groups_error: "Не удалось создать группу",
    reName_groups_success: "Данные изменены успешно",
    reName_groups_errors: "Не удалось переименовать группу",
    delete_groups_success: "Группа удалена",
    delete_groups_error: "Не удалось удалить группу",
    deleteGroupsQuestion: "Вы уверены, что хотите удалить эту группу?",
    format_not_selected: "Формат не определён",

    group: "Группа",
    placeholder_choose_group: "Выберите группу",
    placeholder_search_group: "Поиск группы",
    changes_group: "Редактирование групп",
    add_new_group: "Новая группа",
    group_title: "Группы функций",
    add_group: "Добавить группу:",
    name: "Название",
    placeholder_name: "Введите название",
    data_collection_period: "Период сбора данных модулем",
    can_id: "CAN ID",
    placeholder_canId: "Выберите CAN ID",
    placeholder_pgn: "Выберите PGN",
    can_type: "CAN Type",
    placeholder_canType: "Выберите CAN Type",
    date_time: "Дата / время",
    status: "Статус",
    result: "Результат",
    date: "Дата",
    action: "Действие",
    check_uvi: "Для создания функции необходимо заполнить УИТ",
    empty_table: "Нет данных для отображения",
    toggle_text: "Формат результата: Dec/Hex",
    choice_period_Title: "Выбор периода",

    changes_CanId: "Редактирование",
    data_can_error: "Ошибка получения данных",
    search_CanId: "Поиск",
    can_name: "Название",
    can_name_placeholder: "Введите название",
    can_mask: "Макса",
    canId: "ID",
    protocol: "Протокол",
    can_period: "Период",
    can_type_extended: "Тип CAN",
    can_action: "Действия",
    placeholder_choose_can_protocol: "Выберите протокол",
    placeholder_choose_period: "Введите период",
    changes_can_success: "Изменения сохранены",
    changes_can_error: "Не удалось сохранить изменения",
    update_can_identifier_success: "Идентификатор успешно изменен",
    update_can_identifier_error: "Не удалось изменить идентификатор",
    delete_can_identifier_question:
      "Вы уверены, что хотите удалить этот идентификатор?",
    cancel_action_question:
      "Вы действительно хотите отменить внесенные изменения?",
    can_input_required: "Обязательное поле",
    can_configurationConfirmed_success: "Конфигурация подтверждена",
    can_configurationConfirmed_error: "Конфигурация не подтверждена",
    can_update_success: "Данные успешно обновлены",
    can_update_error: "Не удалось обновить данные",
    can_period_validation: "Положительные дробные значения",
    can_period_min_error: "Минимальное значение 0.001",
    can_downloaded: "Загружено",
    can_from: "из",
    can_unconfirmed: "/ Не подтверждено",
    can_read_more: "Подробнее",
    can_attention: "Внимание!",
    can_attention_extends:
      "не подтверждено. Повторите отправку данных или измените параметры CAN ID.",
    can_format_validate_standard:
      "Максимальное количество идентификаторов со стандартным форматом (11 bit) - 2",
    can_format_validate_extended:
      "Максимальное количество идентификаторов с расширенным форматом (29 bit) - 13",

    data_type: "Тип данных",
    data_type_label: "Количество байт",
    placeholder_choose_data_type: "Выберите тип данных",
    data_byte: "Номер байта",
    placeholder_choose_data_byte: "Выберите номер байта",
    data_bit: "Номер бита",
    placeholder_choose_data_bit: "Номер бита",
    signed_unsigned: "Формат данных",
    placeholder_signed_unsigned: "Формат данных",
    order: "Порядок байтов",
    placeholder_order: "Порядок байтов",
    status_settings_title: "Настройки статусов",
    status_error_table: "Ошибка",
    status_success_table: "Успех",
    status_undefined_table: "Не определен",
    status_settings_choice: "Выбор статуса",
    criteria_params_title: "Параметры",
    placeholder_criteria_params: "Выберите параметр",
    value_title: "Значение",
    placeholder_value: "Выберите значение",
    placeholder_value_input: "Введите значение",
    module_roaming: "Роуминг",
    transportAPN: "APN",
    placeholder_APN_transport: "Введите APN",
    nameAPN: "Имя APN",
    placeholder_userName_transport: "Введите имя",
    PasswordAPN: "Пароль APN",
    placeholder_password_transport: "Введите пароль",
    numberPhoneModule: "Номер телефона",
    pinSimCard: "Pin Sim-карты",
    settings_title_module_block: "Настройки SIM",
    placeholder_pin_transport: "Введите Pin",
    placeholder_phoneNumber_transport: "Введите номер телефона",
    placeholder_spn: "Выберите SPN",

    error_page_title: "Страница не найдена",

    user_list_title: "Список пользователей",
    user_email_confirmed: "Подтвержден",
    user_email_notConfirmed: "Не подтвержден",
    user_id: "Id пользователя",
    user_email: "Email пользователя",
    user_email_status: "Статус Email",
    user_email_confirm: "Подтвержден",
    user_email_not_confirm: "Не подтвержден",
    user_creation_date: "Дата создания пользователя",
    user_roles: "Роли",
    user_refresh_token_expiry_time: "Дата истечения refresh-токена",
    user_revoke_token_question:
      "Вы действительно хотите отозвать токен пользователя?",
    user_revoke_token_success: "Токен пользователя успешно отозван",
    user_revoke_token_error: "Не удалось отозвать токен пользователя",
    user_revoke_all_token_question:
      "Вы хотите отозвать токены всех пользователей?",
    user_revoke_all_token_success: "Токены всех пользователей успешно отозваны",
    user_revoke_all_token_error: "Не удалось отозвать токены пользователей",
    user_revoke_token: "Отозвать токен пользователя",
    user_delete_user_success: "Пользователь успешно удален",
    user_delete_user_error: "Ошибка при удалении пользователя",

    revoke_company_token_success: "Токен компании успешно отозван",
    revoke_company_token_error: "Не удалось отозвать токен компании",
    revoke_token_question: "Вы действительно хотите отозвать токен?",

    update_company_token: "Обновление токена",
    update_company_token_success: "Токен компании успешно обновлен",
    update_company_token_error: "Не удалось обновить токен компании",
    update_company_token_question: "Вы хотите отменить обновление токена?",

    roles_title: "Роли",
    current_role: "Текущие роли",
    all_roles: "Все роли",
    role_assigned_success: "Роль успешно назначена",
    role_assigned_error: "Не удалось назначить роль",
    change_role: "Сменить роль",

    chosen: "выбрано",

    create_new_user: "Создать пользователя",
    userName: "Никнейм пользователя",
    placeholder_userName: "Введите никнейм пользователя",
    email: "Email пользователя",
    placeholder_email: "Введите email пользователя",
    login_user: "Логин пользователя",
    placeholder_login_user: "Введите логин пользователя",
    password_user: "Пароль пользователя",
    placeholder_password_user: "Введите пароль пользователя",
    firstName: "Имя пользователя",
    placeholder_firstName: "Введите имя пользователя",
    secondName: "Фамилия пользователя",
    placeholder_secondName: "Введите фамилию пользователя",
    dateBirth: "Дата рождения",
    placeholder_dateBirth: "Выберите дату рождения",
    user_gender: "Пол",
    user_man: "Мужской",
    user_female: "Женский",
    createUser_success: "Пользователь успешно создан",
    createUser_error: "Не удалось создать пользователя",
    placeholder_user_gender: "Выберите пол",
    users_roles: "Роль",
    placeholder_users_roles: "Выберите роль",
    user_create_question: "Вы хотите отменить создание пользователя?",
    user_update_question: "Вы хотите отменить редактирование пользователя?",
    updateUser_success: "Пользователь успешно обновлен",
    updateUser_error: "Не удалось обновить пользователя",
    update_user_title: "Редактирование пользователя",
    user_delete_user_question: "Вы действительно хотите удалить пользователя?",

    softwareVersion: "Версия программного обеспечения",
    status_error_update: "Ошибка",
    status_success_update: "Обновлено",
    status_loading_update: "Загрузка...",
    transport_name_title: "Транспорт",
    transport_uvi_title: "УИТ",
    transport_software_version_title: "Версия ПО",
    transport_status_title: "Статус",
    transport_date_time_title: "Дата",
    update_success: "Обновление ПО началось...",
    show_only_title: "Показывать только",
    search_by_date: "Поиск по дате",
    clear_choice: "Очистить выбор",

    connection_history: "История подключений",
    log_table_status: "Статус",
    log_table_date: "Дата",
    getLogModuleError: "Не удалось получить историю подключений",
    updateLogModuleSuccess: "Данные успешно обновлены",
    updateLogModuleError: "Ошибка при обновлении данных",
    clearLogModuleSuccess: "История подключений успешно очищена",
    clearLogModuleError: "Ошибка при очистке истории подключений",
    deleteLogQuestion: "Вы уверены, что хотите очистить историю подключений?",
    chronology: "Хронология",
    accuracyGps: "Точность GPS",
    apply: "Применить",
    locationOfTransportation: "Местоположение Транспорта",
    send: "Отправить",
    missing_uvi_error_message: "Не удалось найти значение UVI",
    error_message_protocol:
      "Максимальное количество идентификаторов со стандартным форматом - 2",
    error_message_id: "Такой ID уже есть",
    pgn_duplicate_error: "Такой PGN уже есть",
  },
  [LOCALES.GERMAN]: {
    signUp: "Anmeldung",
    signUp_companyToken: "Handelsregisternummer",
    signUp_create: "Ein Konto erstellen",
    signUp_email: "Email",
    signUp_password: "Passwort",
    singUp_password_repeat: "Wiederholen Sie das Passwort",
    signUp_submit: "Bestätigen",
    signUp_logIN: "Einloggen",
    signUp_question: "Haben Sie keinen Account?",
    signUp_success:
      "Erfolgreich! Bestätigen Sie die Registrierung, indem Sie auf den Link klicken, der Ihnen per E-Mail zugesandt wurde",
    signUp_errors: "Der Benutzer mit dem angegebenen Login existiert bereits",
    accept: "Ich stimme der Verarbeitung meiner persönlichen Daten zu",

    login_title: "Betreten",
    login_subtitle:
      "Geben Sie zur Authentifizierung Ihren Benutzernamen und Ihr Passwort ein",
    login: "E-Mail",
    password: "Passwort",
    logIn_forgot: "Mich erinnern",
    logIn_remember: "Passwort vergessen",
    entry: "Eingeben",
    logIn_create_account: "Ein Konto erstellen",
    login_question: "Hast du einen Account?",
    logIn_message_success: "erfolgreiche Autorisierung",
    login_message_errors: "Ungültiger Benutzername und / oder Passwort",
    login_message_warning: "",
    login_email_notConfirm:
      "E-Mail nicht bestätigt. Die Bestätigungs-E-Mail wurde erneut gesendet.",
    login_in_demo: "Einloggen im Demo",

    token_updated_message: "Sitzung erfolgreich verlängert",
    token_message_expiration:
      "Ihre Sitzung ist abgelaufen. Bitte versuchen Sie es erneut",
    reset_password: "Passwort erfolgreich aktualisiert",
    reset_password_error:
      "Ihr Passwort konnte nicht zurückgesetzt werden. Versuchen Sie es erneut oder fordern Sie die E-Mail erneut an",

    PWReset: "Setzen Sie das Passwort zurück",
    PWReset_password_reset: "Passwort zurücksetzen",
    PWReset_reset_link:
      "Ein Link zum Zurücksetzen wird an Ihren Posteingang gesendet",
    PWReset_email: "Email",
    PWReset_reset_password: "Link zum Zurücksetzen des Passworts senden",
    PWReset_go_back: "zurück",
    send_letter:
      "Eine E-Mail zum Zurücksetzen Ihres Passworts wurde an Sie gesendet",
    error_send_letter:
      "Beim Senden der E-Mail ist ein Fehler aufgetreten! E-Mail erneut anfordern",

    data_receiving_error: "Fehler beim Abrufen der Daten",
    data_functions_error: "Funktionsliste nicht erhalten",

    users: "Benutzer",
    user_name: "Name",
    user_surname: "Nachname",
    user_role: "Rolle",

    administration: "Software-Aktualisierung",
    vehicles_list: "Liste der Fahrzeuge",
    system_list: "Systemprotokoll",
    change_password_menu: "Kennwort ändern",
    log_out: "Ausloggen",

    change_password_title: "Change password",
    current_password_title: "Aktuelles Passwort",
    change_current_password: "das Passwort wurde erfolgreich geändert",
    error_current_password:
      "Beim Ändern des Passworts ist ein Fehler aufgetreten. versuchen Sie es nochmal",
    change_password_question: "Möchten Sie die Passwortänderung abbrechen?",
    placeholder_current_password: "Passwort eingeben",
    new_password: "Neues Passwort",
    placeholder_new_password: "Geben Sie ein neues Kennwort ein",
    confirm_password_title: "Wiederholen Sie das Passwort",
    create_new_password_title: "Erstellen eines neuen Passworts",

    transportSearch: "Transportsuche",
    tableSearch: "Suche",
    table_empty: "Keine Daten zum Anzeigen",

    verification_title: "Deine Email wurde bestätigt!",
    verification_subtitle:
      "Um zu beginnen, müssen Sie sich anmelden und auf die Schaltfläche unten klicken",
    verification: "Anmeldung",

    save: "Speichern",
    edit: "Bearbeiten",
    cancel: "Stornieren",
    leave_page: "Hinausgehen",
    stay_page: "Bleiben",
    restore: "Wiederherstellen",
    overwrite: "Überschreiben",
    confirm: "Bestätigen",
    delete: "Löschen",
    add_criteria: "Kriterien hinzufügen",
    button_back: "Zurück",
    rename: "Umbenennen",
    add_can: "Neue CAN-ID hinzufügen",
    revoke: "Token widerrufen",
    create: "Erstellen",
    close: "Schließen",
    update: "Aktualisierung",
    settings_module_button: "Modul Einstellungen",
    restart_button: "Neustart",
    send_sms_button: "SMS senden mit Einstellungen",
    send_sms_success: "Nachricht erfolgreich gesendet",
    send_sms_error: "Fehler beim Senden von SMS. Erneut versuchen",
    send_sms_warning_wait: "SMS nach 60 Sekunden erneut senden!",
    send_sms_error_no_uvi: "UVI fehlt. Es wurde keine SMS gesendet",
    send_sms_wait: "in {time}s",
    button_upload: "Hochladen",
    button_clear: "Löschen",
    search: "Suche",

    input_required: "Pflichtfeld",
    input_maxLength: "Maximal 38 Zeichen für das Feld",
    validate_password:
      "Passwort mindestens 6 Zeichen, muss Klein-/Großbuchstaben, Zahlen, mindestens ein Sonderzeichen #?!@$%^&*-+ enthalten",
    validate_email: "Ungültiges E-Mail-Adressformat",
    match_password: "Die Passwörter müssen übereinstimmen",
    validate_format_Guid: "Ungültiges GUID-Format",
    validate_CanID:
      "Die Can-ID darf keine kyrillischen Zeichen oder Sonderzeichen enthalten",
    validate_value: "Gültige Werte 0 oder 1",
    validate_parameterId: "Gültige Werte (=) oder (≠)",
    canId_validation: "Nur HEX (ohne 0x) zulässig",
    canId_validation_extended_true:
      "Die Länge der Can-ID muss 8 Zeichen betragen",
    canId_validation_extended_false:
      "Die Länge der Can-ID muss 3 Zeichen betragen",
    canId_validation_extended_true_maxValue: "Maximaler Wert 1FFFFFFF",
    canId_validation_extended_false_maxValue: "Maximaler Wert 7FF",
    max_length_error: "Maximale Länge - 45",
    pgn_validation: "Nur 5 Ziffern in PGN",

    transportTitle: "Erstellen eines neuen Fahrzeugs",
    nameTransport: "Transportname",
    number: "Transportnummer",
    vin: "Vin Nummer",
    notes: "Anmerkungen",
    transport_coordinates: "Transportkoordinaten",
    coordinate_collection_period: "Koordinatensammlungszeitraum",
    transport_UVI: "UVI",
    settings_module: "Modul Einstellungen",
    transportImei: "Imei Transport",
    simNumber: "Sim-nummer",
    simPin: "Sim-pin",
    canInterfaceSpeed: "Can-Schnittstellengeschwindigkeit",
    versionFirmware: "Firmware-Version",
    logSize: "Log-Größe",
    validate_number: "Nur positive Zahlen sind zulässig",
    type_transport: "Art des Transports",
    placeholder_name_transport: "Geben Sie den Namen des Transports ein",
    placeholder_imei_transport: "Eingeben Imei",
    placeholder_logSize: "Geben Sie die Log-Größe ein",
    placeholder_phone_number:
      "Beginnen Sie mit der Eingabe Ihrer Telefonnummer...",
    placeholder_simPin: "Geben Sie den Sim-Pin ein",
    placeholder_Vin: "Geben Sie die VIN-Nummer ein",
    placeholder_licensyPlate: "Geben Sie die Transportnummer ein",
    placeholder_type_transport: "Transportart auswählen",
    placeholder_coordinates: "X1 Y1",
    coordinates_text: "Koordinaten nicht angegeben",
    placeholder_notes: "Textbeschreibung...",
    placeholder_collection_period: "Zeitraum auswählen",
    createTransportQuestion:
      "Möchten Sie die Erstellung des Transports abbrechen?",
    archived_transport: "Das von Imei angegebene Auto wurde im Archiv gefunden",
    archived_transport_title:
      "Im Archiv wurde ein Fahrzeug mit der angegebenen Imei gefunden",
    archivedTransportQuestion:
      "Soll ich das Fahrzeug aus dem Archiv wiederherstellen oder mit neuen Daten überschreiben?",
    duplicate_vehicle:
      "Es existiert bereits eine Maschine mit der angegebenen Imei",
    duplicate_imei_vehicle: "Änderungen konnten nicht gespeichert werden",
    deleteTransportQuestion:
      "Sind Sie sicher, dass Sie diesen Transport löschen möchten?",
    deleteTransportSuccess: "Transport erfolgreich gelöscht",
    deleteTransportError: "Error deleting transport",
    buttonYes: "Ja",
    buttonNo: "Nein",
    select_format: "Welches Protokoll werden Sie verwenden?",
    transport_info: "Es gibt nicht gespeicherte Änderungen",
    transport_question:
      "Sind Sie sicher, dass Sie die Seite verlassen möchten?",
    transport_warning: "Nicht gespeicherte Änderungen gehen verloren.",
    transport_error_byId: "Fahrzeugdaten konnten nicht abgerufen werden",
    FunctionsAndTransportUpdateSuccess: "Daten erfolgreich aktualisiert",
    FunctionsAndTransportUpdateFail: "Fehler beim Aktualisieren der Daten",
    function_delete_success: "Funktion entfernt",
    function_delete_error: "Beim Löschen ist ein Fehler aufgetreten",
    vehicle_change_success: "Daten erfolgreich aktualisiert",
    createTransportSuccess: "Transport erfolgreich erstellt",
    createTransportError: "Fehler beim",
    getModuleError: "Modul konnte nicht abgerufen werden",
    getUpdateError: "Daten konnten nicht aktualisiert werden",
    resetModuleSuccess:
      "Befehl zum Zurücksetzen erfolgreich an das Modul gesendet",
    resetModuleError: "Fehler beim Neustart des Moduls",
    resetModuleQuestion: "Möchten Sie das Modul wirklich neu starten?",
    connection_settings_confirmed: "Konfiguration bestätigt",
    connection_settings_notConfirmed: "Konfiguration nicht bestätigt",

    table_page_text: "Auf Seite anzeigen",
    table_select_allPages: "Alle",

    add_function: "Funktion hinzufügen",
    update_function: "Aktualisierung",
    transport_list: "Liste der Transportmittel",
    add_new: "Neue hinzufügen",
    create_function_success: "Die Funktion wurde erfolgreich erstellt",
    create_function_error: "Funktion konnte nicht erstellt werden",
    update_function_success: "Funktion erfolgreich geändert",
    update_function_error: "Die Funktion konnte nicht aktualisiert werden",
    add_function_error: "Funktion konnte nicht hinzugefügt werden",
    error_clickTransport:
      "Die Fahrzeugauswahl ist im Funktionsbearbeitungsmodus nicht verfügbar",
    deleteFunctionQuestion: "Möchten Sie diese Funktion wirklich entfernen?",
    create_function_title: "Erstellen einer Funktion",
    edit_function_title: "Bearbeiten einer Funktion",
    add_new_groups_success: "The group was added successfully",
    add_new_groups_error: "Die Gruppe konnte nicht erstellt werden",
    reName_groups_success: "Daten erfolgreich geändert",
    reName_groups_errors: "Die Gruppe konnte nicht umbenannt werden",
    delete_groups_success: "Gruppe gelöscht",
    delete_groups_error: "Die Gruppe konnte nicht gelöscht werden",
    deleteGroupsQuestion:
      "Sind Sie sicher, dass Sie diese Gruppe löschen möchten?",
    format_not_selected: "Format nicht definiert",

    group: "Gruppe",
    changes_group: "Bearbeiten von Gruppen",
    group_title: "Funktionsgruppen",
    add_new_group: "Eine neue Gruppe",
    placeholder_choose_group: "Wähle die Gruppe",
    placeholder_search_group: "Gruppensuche",
    add_group: "Fügen Sie eine Gruppe hinzu:",
    data_collection_period: "Erfassungszeitraum der Moduldaten",
    name: "Name",
    placeholder_name: "Geben Sie den Titel ein",
    can_id: "CAN ID",
    placeholder_canId: "CAN-ID auswählen",
    placeholder_pgn: "PGN auswählen",
    can_type: "CAN Type",
    placeholder_canType: "Geben Sie die CAN Type ein",
    date_time: "Datum / Zeit",
    status: "Status",
    result: "Ergebnis",
    date: "Datum",
    action: "Aktion",
    check_uvi: "Um eine Funktion zu erstellen, müssen Sie UVI ausfüllen",
    empty_table: "Keine Daten zum Anzeigen",
    toggle_text: "Ergebnisformat: Dec/Hex",
    choice_period_Title: "Zeitraumauswahl",

    changes_CanId: "Bearbeitung von",
    data_can_error: "Fehler beim Empfangen von Daten",
    search_CanId: "Suche",
    can_name: "Titel",
    can_name_placeholder: "Einen Namen eingeben",
    can_mask: "Maske",
    canId: "ID",
    protocol: "Protokoll",
    can_period: "Periode",
    can_type_extended: "CAN Type",
    can_action: "Aktionen",
    placeholder_choose_can_protocol: "Wählen Sie das Protokoll",
    placeholder_choose_period: "Geben Sie den Zeitraum",
    changes_can_success: "Änderungen gespeichert",
    changes_can_error: "Änderungen konnten nicht gespeichert werden",
    update_can_identifier_success: "ID erfolgreich geändert",
    update_can_identifier_error: "Die Kennung konnte nicht geändert werden",
    delete_can_identifier_question:
      "Möchten Sie diesen Identifikator wirklich löschen?",
    cancel_action_question:
      "Sind Sie sicher, dass Sie die Änderungen verwerfen möchten?",
    can_input_required: "Erforderliches Feld",
    can_configurationConfirmed_success: "Konfiguration bestätigt",
    can_configurationConfirmed_error: "Konfiguration nicht bestätigt",
    can_update_success: "Daten erfolgreich aktualisiert",
    can_update_error: "Daten konnten nicht aktualisiert werden",
    can_period_validation: "Nur Dezimalwerte",
    can_period_min_error: "Der Mindestwert beträgt 0,001",
    can_downloaded: "Heruntergeladen",
    can_from: "von",
    can_unconfirmed: "/ Unbestätigt",
    can_read_more: "Mehr lesen",
    can_attention: "Achtung!",
    can_attention_extends:
      "nicht bestätigt wird. Senden Sie die Daten erneut oder ändern Sie die CAN-ID-Parameter.",
    can_format_validate_standard:
      "Maximale Anzahl von Identifikatoren mit Standardformat (11 Bit) - 2",
    can_format_validate_extended:
      "Maximale Anzahl von IDs mit erweitertem Format (29 Bit) - 13",

    data_type: "Datentyp",
    data_type_label: "Anzahl der Bytes",
    placeholder_choose_data_type: "Datentyp auswählen",
    data_byte: "Byte-Nummer",
    placeholder_choose_data_byte: "Bytenummer auswählen",
    data_bit: "Bitnummer",
    placeholder_choose_data_bit: "Bitnummer",
    signed_unsigned: "Datei Format",
    placeholder_signed_unsigned: "Datenformat",
    order: "Bytereihenfolge",
    placeholder_order: "Bytereihenfolge",
    status_settings_title: "Statuseinstellungen",
    status_settings_choice: "Status auswählen",
    status_error_table: "Fehler",
    status_success_table: "Erfolg",
    status_undefined_table: "Unbestimmt",
    criteria_params_title: "Optionen",
    placeholder_criteria_params: "Wähle eine Option",
    value_title: "Bedeutung",
    placeholder_value: "Wähle Wert",
    placeholder_value_input: "Wert eingeben",
    module_roaming: "Roaming",
    transportAPN: "APN(Sim)",
    placeholder_APN_transport: "Geben Sie APN ein",
    nameAPN: "APN-Benutzername",
    placeholder_userName_transport: "Geben Sie den Namen ein",
    PasswordAPN: "Passwort APN",
    placeholder_password_transport: "Geben Sie das Passwort ein",
    numberPhoneModule: "Telefonnummer",
    pinSimCard: "Sim-Karte-Pin",
    settings_title_module_block: "SIM-Einstellungen",
    placeholder_pin_transport: "Geben Sie den Pin ein",
    placeholder_phoneNumber_transport: "Geben Sie die Telefonnummer ein",
    placeholder_spn: "SPN auswählen",

    error_page_title: "Seite nicht gefunden",

    user_list_title: "Benutzerliste",
    user_email_confirmed: "Bestätigt",
    user_email_notConfirmed: "Nicht bestätigt",
    user_id: "Benutzer-ID",
    user_email: "Benutzer-E-Mail",
    user_email_status: "E-Mail-Status",
    user_email_confirm: "Bestätigt",
    user_email_not_confirm: "Nicht bestätigt",
    user_creation_date: "Benutzererstellungsdatum",
    user_roles: "Rollen",
    user_refresh_token_expiry_time: "Ablaufdatum des Aktualisierungstokens",
    user_revoke_token_question:
      "Möchten Sie das Benutzertoken wirklich widerrufen?",
    user_revoke_token_success: "Benutzertoken erfolgreich widerrufen",
    user_revoke_token_error: "Das Benutzertoken konnte nicht widerrufen werden",
    user_revoke_all_token_question:
      "Möchten Sie alle Benutzertoken widerrufen?",
    user_revoke_all_token_success: "Alle Benutzertoken erfolgreich widerrufen",
    user_revoke_all_token_error:
      "Die Benutzertoken konnten nicht widerrufen werden",
    user_revoke_token: "Benutzertoken widerrufen",
    revoke_company_token_success: "Firmen-Token erfolgreich widerrufen",
    revoke_company_token_error:
      "Fehler beim Widerrufen des Unternehmens-Tokens",
    revoke_token_question: "Möchten Sie das Token wirklich widerrufen?",
    update_company_token: "Token aktualisieren",
    update_company_token_success: "Firmen-Token erfolgreich aktualisiert",
    update_company_token_error:
      "Fehler beim Aktualisieren des Unternehmens-Tokens",
    update_company_token_question: "Möchten Sie das Token-Update abbrechen?",

    roles_title: "Rollen",
    current_role: "Aktuelle Rolle",
    all_roles: "Alle Rollen",
    role_assigned_success: "Rolle erfolgreich zugewiesen",
    role_assigned_error: "Fehler beim Zuweisen der Rolle",
    change_role: "Rolle ändern",

    chosen: "Ausgewählt",

    create_new_user: "Benutzer erstellen",
    userName: "Benutzername",
    placeholder_userName: "Benutzernamen eingeben",
    email: "Benutzer-E-Mail",
    placeholder_email: "Benutzer-E-Mail eingeben",
    login_user: "Benutzer-Login",
    placeholder_login_user: "Benutzer-Login",
    password_user: "Benutzerpasswort",
    placeholder_password_user: "Benutzerpass",
    firstName: "Benutzername",
    placeholder_firstName: "Benutzernamen eingeben",
    secondName: "Benutzername",
    placeholder_secondName: "Benutzernamen eingeben",
    dateBirth: "Geburtsdatum",
    placeholder_dateBirth: "Geburtsdatum auswählen",
    user_gender: "Geschlecht",
    user_man: "Männlich",
    user_female: "Weiblich",
    createUser_success: "Benutzer erfolgreich erstellt",
    createUser_error: "Benutzer konnte nicht erstellt werden",
    placeholder_user_gender: "Geschlecht auswählen",
    users_roles: "Rollen",
    placeholder_users_roles: "Rolle auswählen",
    user_create_question: "Möchten Sie die Benutzererstellung abbrechen?",
    user_update_question: "Möchten Sie die Benutzerbearbeitung abbrechen?",
    updateUser_success: "Benutzer erfolgreich aktualisiert",
    updateUser_error: "Benutzer konnte nicht aktualisiert werden",
    update_user_title: "Benutzer bearbeiten",
    user_delete_user_question: "Möchten Sie den Benutzer wirklich löschen?",
    user_delete_user_success: "Benutzer erfolgreich gelöscht",
    user_delete_user_error: "Fehler beim Löschen des Benutzers",

    softwareVersion: "Software-Version",
    status_error_update: "Fehler",
    status_success_update: "Aktualisiert",
    status_loading_update: "Laden...",
    transport_name_title: "Transport",
    transport_uvi_title: "UVI",
    transport_software_version_title: "Software-Version",
    transport_status_title: "Status",
    transport_date_time_title: "Datum",
    update_success: "Aktualisierung der Software hat begonnen...",
    show_only_title: "Nur anzeigen",
    search_by_date: "Nach Datum suchen",
    clear_choice: "Auswahl löschen",

    connection_history: "Verbindungshistorie",
    log_table_status: "Status",
    log_table_date: "Datum",
    getLogModuleError: "Verbindungsverlauf konnte nicht abgerufen werden",
    updateLogModuleSuccess: "Daten erfolgreich aktualisiert",
    updateLogModuleError: "Fehler beim Aktualisieren der Daten",
    clearLogModuleSuccess: "Verlauf erfolgreich gelöscht",
    clearLogModuleError: "Fehler beim Löschen des Verlaufs",
    deleteLogQuestion: "Möchten Sie den Verlauf wirklich löschen?",
    chronology: "Chronologie",
    accuracyGps: "GPS-Genauigkeit",
    apply: "Bewerbung",
    locationOfTransportation: "Ort der Beförderung",
    send: "Senden",
    missing_uvi_error_message: "UVI-Wert konnte nicht gefunden werden",
    error_message_protocol:
      "Maximale Anzahl von Identifikatoren mit Standardformat - 2",
    error_message_id: "Es gibt bereits eine solche ID",
    pgn_duplicate_error: "Es gibt bereits eine solche PGN",
  },
};

import React, { useEffect, useMemo, useState } from "react";
import InputMask from "react-input-mask";
import "./SettingsModule.scss";
import ButtonBack from "../buttons/ButtonBack";
import { ReactComponent as BtnSve } from "./svg/Save.svg";
import ButtonEdit from "../buttons/ButtonEdit";
import Select from "react-select";
import { FormattedMessage, useIntl } from "react-intl";
import {
  DataCanInterFaceSpeed,
  DataCollectionPeriod,
  DataRoamingPeriod,
} from "../Fuction/data";
import { customStyles } from "../Fuction/styleSelector";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ModuleConnectionDTO,
  ModuleDTO,
  VehicleDTO,
} from "../../../api/auth/apiClient";
import { mapClient } from "../../../api/auth/AxiosInstanse";
import {
  NotificationState,
  NotificationType,
} from "../../Notification/notificationTypes";
import Modal from "../Modal/Modal";
import { ReactComponent as Confirmed } from "./svg/confirmed.svg";
import { ReactComponent as NotConfirmed } from "./svg/notConfirmed.svg";
import { Hint } from "@skbkontur/react-ui";
import ButtonResetModule from "../buttons/ButtonResetModule";
import ButtonSendSms from "../buttons/ButtonSendSms";
import ModalNotification from "../../CreateTransport/Modal_notification/ModalNotification";
import LogModule from "./LogModule";

type PropsSettingsModule = {
  onClickButtonBack: (showModal: boolean) => void;
  buttonActive: boolean;
  onChangeDisabled: (isDisabled: boolean) => void;
  isDisabled: boolean;
  activeTransport: VehicleDTO;
  showNotification: NotificationState;
  onChangeNotificationState: (state: NotificationState) => void;
  onShowTransportEditorList: (showTransportEditor: boolean) => void;
  setShowModuleSettings: (showModuleSettings: boolean) => void;
  getModule: (uvi: string) => void;
  module: ModuleDTO | undefined;
  setModule: (module: ModuleDTO) => void;
  logModule: Array<ModuleConnectionDTO> | undefined;
  getLogModule: (uvi: string) => void;
};

function SettingsModule(props: PropsSettingsModule) {
  const intl = useIntl();
  const [buttonActive, setButtonActive] = useState(false);
  const [moduleBeforeEdit, setModuleBeforeEdit] = useState<ModuleDTO>();
  const [showModalModule, setShowModalModule] = useState<boolean>(false);
  const [isDisabledModuleEdit, setIsDisabledModuleEdit] =
    useState<boolean>(true);
  const [showModalReset, setShowModalReset] = useState<boolean>(false);
  const [numberPhone, setNumberPhone] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm<ModuleDTO>({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return props.module ?? {};
    }, [module]),
  });

  const phoneNumber = watch("sim.phoneNumber");
  useEffect(() => setNumberPhone(!!phoneNumber?.trim()), [phoneNumber]);

  useEffect(() => {
    reset(props.module);
  }, [props.module, reset]);

  useEffect(() => {
    props.getModule(props.activeTransport.uvi!);
    props.getLogModule(props.activeTransport.uvi!);
  }, []);

  const formatPhoneNumberForServer = (phone: string) => {
    return `+${phone.replace(/\D/g, "")}`;
  };

  async function resetModule(uvi: string) {
    try {
      await mapClient.resetModule(uvi);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "resetModuleSuccess" }),
      });
    } catch {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "resetModuleError" }),
      });
    }
    setShowModalReset(false);
    await props.getModule(props.activeTransport.uvi!);
  }

  function onChangeDisabled(isDisabledModuleEdit: boolean) {
    setIsDisabledModuleEdit(isDisabledModuleEdit);
    setModuleBeforeEdit({ ...props.module } as ModuleDTO);
  }

  function onClickButtonBack(showModal: boolean) {
    if (!isDisabledModuleEdit) {
      setShowModalModule(showModal);
    } else {
      onClose();
    }
    setButtonActive(!buttonActive);
  }

  function onClose() {
    props.setModule(moduleBeforeEdit!);
    props.setShowModuleSettings(false);
    props.onShowTransportEditorList(true);
  }

  const onSubmit: SubmitHandler<ModuleDTO> = async (data) => {
    try {
      const formattedPhone = data.sim?.phoneNumber
        ? formatPhoneNumberForServer(data.sim.phoneNumber)
        : undefined;

      const updatedData = {
        ...data,
        sim: {
          ...data.sim,
          phoneNumber: formattedPhone,
        },
      };

      await mapClient.updateModule(data);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "vehicle_change_success" }),
      });
    } catch (error: any) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: `${intl.formatMessage({ id: "getUpdateError" })}: ${
          error?.errors ? error.errors[0] : "Unknown error"
        }`,
      });
    }
    await props.getModule(props.activeTransport.uvi!);
    onChangeDisabled(true);
  };

  return (
    <div className="settingsModuleList">
      {showModalModule ? (
        <div>
          <Modal onClickStayPage={onClickButtonBack} onClosePage={onClose} />
        </div>
      ) : null}

      {showModalReset ? (
        <div>
          <ModalNotification
            modalQuestion={intl.formatMessage({ id: "resetModuleQuestion" })}
            onYesModalActions={() => setShowModalReset(false)}
            onNoModalActions={() => resetModule(props.activeTransport.uvi!)}
          />
        </div>
      ) : null}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="settingsModuleContainer"
      >
        <div className="settingsModuleHeader">
          <ButtonBack
            onClickButtonBack={onClickButtonBack}
            buttonActive={buttonActive}
          />
          <h2>{props.activeTransport.name}</h2>
          <div className="ml-3">
            {props.module?.connectionSettingsConfirmed ? (
              <Hint
                text={intl.formatMessage({
                  id: "connection_settings_confirmed",
                })}
              >
                <Confirmed />
              </Hint>
            ) : (
              <Hint
                text={intl.formatMessage({
                  id: "connection_settings_notConfirmed",
                })}
              >
                <NotConfirmed />
              </Hint>
            )}
            {/*{module?.isConnected ? <span className="connected">Online</span> : <span className="disconnected">Offline</span>}*/}
          </div>

          <div className="flex ml-auto">
            {!isDisabledModuleEdit ? (
              <button type="submit" className="btn ml-5">
                <BtnSve />
                <span className="ml-2.5">
                  <FormattedMessage id="save" />
                </span>
              </button>
            ) : null}
            <ButtonEdit
              onChangeDisabled={onChangeDisabled}
              isDisabled={isDisabledModuleEdit}
            />
          </div>
        </div>
        <div className="settingsModuleBlockContainer">
          <div className="settingsItemBlock">
            <div className="descriptionBlock">
              <div>
                <div>
                  <div className="mb-1">
                    <span>
                      <FormattedMessage id="transportImei" />
                    </span>
                  </div>
                  <input
                    {...register("imei")}
                    type="text"
                    className={`${isDisabledModuleEdit ? "disabled" : ""}`}
                    disabled={isDisabledModuleEdit}
                    placeholder={intl.formatMessage({
                      id: "placeholder_imei_transport",
                    })}
                  />
                </div>
                <div className="mt-2">
                  <div className="mb-1">
                    <span>
                      <FormattedMessage id="canInterfaceSpeed" />
                    </span>
                  </div>
                  <Controller
                    control={control}
                    name={"canInterfaceSpeed"}
                    rules={{
                      required: intl.formatMessage({ id: "input_required" }),
                    }}
                    render={({ field: { value, onChange, name, ref } }) => (
                      <Select
                        name={name}
                        ref={ref}
                        options={DataCanInterFaceSpeed.map((t) => {
                          return { value: t.value, label: t.label };
                        })}
                        isSearchable={false}
                        isClearable={false}
                        styles={customStyles}
                        isDisabled={isDisabledModuleEdit}
                        onChange={(value) => {
                          onChange(value?.value);
                        }}
                        value={
                          DataCanInterFaceSpeed.map((t) => {
                            return { value: t.value, label: t.label };
                          }).find((t) => t.value === value) ?? undefined
                        }
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <div>
                  <div className="mb-1">
                    <span>
                      <FormattedMessage id="versionFirmware" />
                    </span>
                  </div>
                  <input
                    {...register("firmware")}
                    type="text"
                    className="versionFirmware"
                    readOnly
                    value={props.module?.firmware}
                  />
                </div>
                <div className="mt-3">
                  <div className="mb-1">
                    <span>
                      <FormattedMessage id="logSize" />
                    </span>
                  </div>
                  <input
                    {...register("logSize", {
                      required: intl.formatMessage({ id: "input_required" }),
                      pattern: {
                        value: /^-?\d+$/,
                        message: intl.formatMessage({
                          id: "validate_number",
                        }),
                      },
                    })}
                    type={"text"}
                    className={`${isDisabledModuleEdit ? "disabled" : ""}`}
                    placeholder={intl.formatMessage({
                      id: "placeholder_logSize",
                    })}
                    disabled={isDisabledModuleEdit}
                  />
                  <div className="mt-1">
                    {errors?.logSize && (
                      <p>{errors?.logSize?.message || "Errors!!!"}</p>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="mt-20">
                  <div className="mb-2">
                    <span>
                      <FormattedMessage id="coordinate_collection_period" />
                    </span>
                  </div>
                  <div>
                    <Controller
                      control={control}
                      name={"coordinatesPeriod"}
                      rules={{
                        required: intl.formatMessage({
                          id: "input_required",
                        }),
                      }}
                      render={({ field: { value, onChange, name } }) => (
                        <Select
                          name={name}
                          options={DataCollectionPeriod.map((period) => ({
                            value: period.value,
                            label: period.label,
                          }))}
                          isSearchable={false}
                          isClearable={false}
                          styles={customStyles}
                          placeholder={intl.formatMessage({
                            id: "placeholder_collection_period",
                          })}
                          isDisabled={isDisabledModuleEdit}
                          onChange={(value) => {
                            onChange(value?.value);
                          }}
                          value={
                            DataCollectionPeriod.map((period) => ({
                              value: period.value,
                              label: period.label,
                            })).find((period) => period.value === value) ??
                            undefined
                          }
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="moduleBlockLine mt-5"></div>
            <div className="settings_moduleBlock">
              <div className="settings_moduleBlock_title mt-5">
                <span className="moduleBlock_title">
                  <FormattedMessage id="settings_title_module_block" />
                </span>
              </div>
              <div className="settingsSim mt-3">
                <div>
                  <div className="mb-1">
                    <span>
                      <FormattedMessage id="numberPhoneModule" />
                    </span>
                  </div>

                  <Controller
                    name="sim.phoneNumber"
                    control={control}
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        mask="+9(999) 999-9999"
                        className={`${isDisabledModuleEdit ? "disabled" : ""}`}
                        disabled={isDisabledModuleEdit}
                        placeholder={intl.formatMessage({
                          id: "placeholder_phoneNumber_transport",
                        })}
                      />
                    )}
                  />

                  <div>
                    <div className="mb-1 mt-2">
                      <span>
                        <FormattedMessage id="transportAPN" />
                      </span>
                    </div>
                    <input
                      {...register("sim.apn")}
                      type="text"
                      className={`${isDisabledModuleEdit ? "disabled" : ""}`}
                      disabled={isDisabledModuleEdit}
                      placeholder={intl.formatMessage({
                        id: "placeholder_APN_transport",
                      })}
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-1">
                    <span>
                      <FormattedMessage id="pinSimCard" />
                    </span>
                  </div>
                  <input
                    {...register("sim.pin")}
                    type="text"
                    className={`${isDisabledModuleEdit ? "disabled" : ""}`}
                    disabled={isDisabledModuleEdit}
                    placeholder={intl.formatMessage({
                      id: "placeholder_pin_transport",
                    })}
                  />
                  <div>
                    <div className="mb-1 mt-2">
                      <span>
                        <FormattedMessage id="nameAPN" />
                      </span>
                    </div>
                    <input
                      {...register("sim.userName")}
                      type="text"
                      className={`${isDisabledModuleEdit ? "disabled" : ""}`}
                      disabled={isDisabledModuleEdit}
                      placeholder={intl.formatMessage({
                        id: "placeholder_userName_transport",
                      })}
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1">
                    <span>
                      <FormattedMessage id="module_roaming" />
                    </span>
                  </div>
                  <div>
                    <Controller
                      control={control}
                      name={"sim.roaming"}
                      rules={{
                        required: intl.formatMessage({ id: "input_required" }),
                      }}
                      render={({ field: { value, onChange, name, ref } }) => (
                        <Select
                          name={name}
                          ref={ref}
                          options={DataRoamingPeriod.map((t) => {
                            return { value: t.value, label: t.label };
                          })}
                          isSearchable={false}
                          isClearable={false}
                          styles={customStyles}
                          isDisabled={isDisabledModuleEdit}
                          onChange={(value) => {
                            onChange(value?.value);
                          }}
                          value={
                            DataRoamingPeriod.map((t) => {
                              return { value: t.value, label: t.label };
                            }).find((t) => t.value === value) ?? undefined
                          }
                        />
                      )}
                    />
                  </div>
                  <div>
                    <div className="mb-1 mt-2">
                      <span>
                        <FormattedMessage id="PasswordAPN" />
                      </span>
                    </div>
                    <input
                      {...register("sim.password")}
                      type="text"
                      className={`${isDisabledModuleEdit ? "disabled" : ""}`}
                      disabled={isDisabledModuleEdit}
                      placeholder={intl.formatMessage({
                        id: "placeholder_password_transport",
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex ml-auto justify-end mt-5">
          <ButtonSendSms
            onChangeNotificationState={props.onChangeNotificationState}
            showNotification={props.showNotification}
            uvi={props.activeTransport.uvi}
            numberPhone={numberPhone}
          />
          <ButtonResetModule setShowModalReset={setShowModalReset} />
        </div>
      </form>
      <div className="logBlock">
        <LogModule
          onChangeNotificationState={props.onChangeNotificationState}
          showNotification={props.showNotification}
          activeTransport={props.activeTransport}
          logModule={props.logModule}
          getLogModule={props.getLogModule}
        />
      </div>
    </div>
  );
}

export default SettingsModule;

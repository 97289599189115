import React from "react";
import "./FormatSelectionModal.scss";
import "../../CreateTransport/Modal_notification/ModalNotification.scss";
import { ReactComponent as CloseBtn } from "../Fuction/CanIdentifier/svg/closeBtn.svg";
import { FormattedMessage } from "react-intl";

interface FormatSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (format: "Standard" | "j1939") => void;
}

const FormatSelectionModal: React.FC<FormatSelectionModalProps> = ({
  isOpen,
  onClose,
  onSelect,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modalContainer">
        <button className="closeModal" onClick={onClose}>
          <CloseBtn />
        </button>
        <div className="modalForm">
          <h2>
            <FormattedMessage id="select_format" />
          </h2>
          <div className="modalButtons">
            <button onClick={() => onSelect("Standard")}>
              <span>Standard</span>
            </button>
            <button onClick={() => onSelect("j1939")}>
              <span>J1939</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormatSelectionModal;
